import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, FormProvider, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'cropperjs/dist/cropper.css';

import {
  Col,
  ConfigProvider,
  Image,
  Input,
  InputNumber,
  List,
  Radio,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
  Upload,
  UploadFile,
  notification,
  theme,
} from 'antd';
import {
  InfoCircleOutlined,
  CheckOutlined,
  EyeOutlined,
  DownloadOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Form from 'antd/es/form';
import {
  Button,
  DatePicker,
  Editor,
  baseColor600,
  baseColor900,
  primaryColor,
} from '@seaters-app/ui';
import {
  getTotalSeatPrice,
  percentageToDecimalString,
  queryClient,
  requestOneTimeUpload,
  uploadFile,
  useDeleteWaitingListImage,
  useFanGroupStore,
  useFetchFanGroupOwnerWaitingList,
  useFetchFanGroupOwnerWaitingListNames,
  useFetchLanguages,
  useUpdateWaitingList,
  useUpdateWaitingListImage,
  useUpdateWaitingListTC,
  useUpdateWaitingListToDraft,
} from '@seaters-app/data-access';
import {
  AssignationMode,
  BillingMode,
  CreateWishListSettingsFormValidatorSchema,
  DisplayMode,
  FanGroupOwnerWaitingListEntity,
  FormTranslationType,
  InvitationApprovalMode,
  InvitationMode,
  PositionsDistributionMode,
  WaitingListStatusEnum,
  fanGroupOwnerWaitingListNames,
  fanGroupOwnerWaitingListsKeys,
  routes,
  usersKeys,
} from '@seaters-app/constants';
import { ImageCropper, StyledText, initialImage } from '@seaters-app/ui-shared';
import { initialTranslations } from '../../../../utils';
import { uploadImage } from '../helpers/uploadFile';
import {
  BillingModeOptionType,
  billingModeOptions,
  useDisplayModeOptions,
  usePositionDistributionOptions,
  timeUnitOptions,
  getWLSettingsStepInitialValues,
  useAccessModeOptions,
} from '../helpers';
import Footer from '../components/Footer';
import {
  convertKeywordsTranslation,
  convertTranslation,
} from '../helpers/convertTransaltions';
import { useTranslation } from 'react-i18next';
import { useCurrentStep } from '../hooks/useCurrentStep';
import EventDetailedInfoModal from '../components/EventDetailedInfoModal';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'antd/lib/upload';

const normFile = (e: UploadChangeParam<UploadFile<RcFile>>) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return { ...e, status: 'success' };
  }
  return e?.fileList;
};

const invitationModeMatch = new Map([
  [true, InvitationMode.ENABLED],
  [false, InvitationMode.DISABLED],
]);

const { Text, Paragraph } = Typography;
const { Option } = Select;

export interface CreateWishListFormType {
  price: FanGroupOwnerWaitingListEntity['price'];
  coverImage: string | null;
  experienceName?: FormTranslationType;
  name: string;
  highlighted: boolean;
  waitingListImageUrl: HTMLCanvasElement | string | null;
  description?: FormTranslationType;
  keywords?: {
    [key: string]: string[];
  };
  billingMode: BillingMode;
  originalPrice: string | number;
  showFreePrice: boolean;
  directSales: boolean;
  termsAndConditionFileURL: string;
  positionsDistributionMode?: string;
  displayMode?: DisplayMode;
  minNumberOfSeatsPerPosition?: number;
  maxNumberOfSeatsPerPosition?: number;
  voucherExpirationTimeOutInMs: number | null;
  fixedNumberOfSeatsPerPosition?: number;
  participationEndDate: Dayjs | null;
  invitationParameters: FanGroupOwnerWaitingListEntity['invitationParameters'];
  billingVariables: FanGroupOwnerWaitingListEntity['billingVariables'];
  prepaidFee: FanGroupOwnerWaitingListEntity['prepaidFee'];
}

export function SettingsStep({ isEdit }: { isEdit?: boolean }) {
  const navigate = useNavigate();

  // commented to check if it caused unnecessary loader
  // const isMutating = !!useIsMutating();

  const lookEntity = queryClient.getQueryData<LookEntity>(usersKeys.look());

  const { wishListId = '' } = useParams<{ wishListId: string }>();
  const { t } = useTranslation();
  const { next, prev } = useCurrentStep();
  const { fanGroup } = useFanGroupStore();
  const positionDistributionOptions = usePositionDistributionOptions();
  const { token } = theme.useToken();

  const [editorShown, setEditorShown] = useState(false);

  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const [eventDescriptionShown, setEventDescriptionShown] =
    useState<boolean>(false);

  const displayModeOptions = useDisplayModeOptions();
  const accessModeOptions = useAccessModeOptions();

  const { data: languagesList } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const {
    data: wishList,
    isLoading: wishListIsLoading,
    isFetched: wishListIsFetched,
  } = useFetchFanGroupOwnerWaitingList(wishListId);

  const { data: wishListNames } = useFetchFanGroupOwnerWaitingListNames(
    fanGroup?.id ?? ''
  );

  const { mutate: updateWishList } = useUpdateWaitingList();
  const { mutateAsync: updateWishListImage } = useUpdateWaitingListImage();
  const { mutateAsync: deleteWishListImage } = useDeleteWaitingListImage();
  const { mutateAsync: updateWishListTC } = useUpdateWaitingListTC();
  const { mutate: updateWLToDraft } = useUpdateWaitingListToDraft();

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [initialUnit, setInitialUnit] =
    useState<keyof typeof timeUnitOptions>('hours');
  const [unit, setUnit] = useState<keyof typeof timeUnitOptions>(initialUnit);
  const [initialDuration, setInitialDuration] = useState<number | null>(null);
  const [duration, setDuration] = useState<number | null>();

  const handleInputChange = (value: number | null) => {
    setInitialDuration(value);
    setInitialUnit(unit);
  };

  useEffect(() => {
    if (wishListIsFetched && wishList?.voucherExpirationTimeOutInMs) {
      setInitialDuration(
        wishList?.voucherExpirationTimeOutInMs /
          timeUnitOptions[initialUnit].value
      );
    }
  }, [wishListIsFetched]);

  const handleUnitChange = (value: keyof typeof timeUnitOptions) => {
    if (value !== initialUnit) {
      setUnit(value);
      const initialValue = timeUnitOptions[initialUnit].value;
      const targetValue = timeUnitOptions[value].value;
      const newDuration = initialDuration
        ? (initialValue / targetValue) * initialDuration
        : null;
      setDuration(newDuration);
    } else {
      setDuration(initialDuration);
      setUnit(value);
    }
  };

  const keywordsInitialValues: { [key: string]: string[] } = {};

  Object.keys(initialTranslations).forEach(
    (key: string) => (keywordsInitialValues[key] = [])
  );
  const initialValues: CreateWishListFormType = getWLSettingsStepInitialValues(
    wishList,
    fanGroup,
    keywordsInitialValues,
    initialUnit
  );

  const methods = useForm<CreateWishListFormType, boolean>({
    mode: 'onBlur',
    resolver: zodResolver(CreateWishListSettingsFormValidatorSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    reset,
    formState: { errors, isDirty, dirtyFields },
    getValues,
  } = methods;

  const positionsDistributionMode = useWatch({
    control,
    name: 'positionsDistributionMode',
  });

  const price = useWatch({
    control,
    name: 'price',
  });

  const accessMode = useWatch({
    control,
    name: 'accessMode',
  });

  const billingMode = useWatch({
    control,
    name: 'billingMode',
  });

  const billingVariables = useWatch({
    control,
    name: 'billingVariables',
  });

  const minNumberOfSeatsPerPosition = useWatch({
    control,
    name: 'minNumberOfSeatsPerPosition',
  });

  const maxNumberOfSeatsPerPosition = useWatch({
    control,
    name: 'maxNumberOfSeatsPerPosition',
  });

  const minNumberOfInvitations = useWatch({
    control,
    name: 'invitationParameters.minNumberOfInvitations',
  });

  const maxNumberOfInvitations = useWatch({
    control,
    name: 'invitationParameters.maxNumberOfInvitations',
  });

  const waitingListImageUrl = useWatch({
    control,
    name: 'waitingListImageUrl',
  });

  const invitationParameters = useWatch({
    control,
    name: 'invitationParameters',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState<string | number>('en');

  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState<
    UploadFile[] | []
  >(
    wishList?.termsAndConditionFileURL
      ? [{ ...initialImage, url: wishList?.termsAndConditionFileURL }]
      : []
  );

  const wishListNamesOptions = useMemo(
    () => wishListNames?.items.map((name) => ({ value: name, label: name })),
    [wishListNames]
  );

  const languages = languagesList
    ? languagesList?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const wlBillingModeOptions = billingModeOptions.reduce<
    BillingModeOptionType[]
  >((acc, option) => {
    if (fanGroup?.availableBillingModes?.includes(option.value)) {
      acc.push(option);
    }
    return acc;
  }, []);

  const onSubmit = async (values: CreateWishListFormType) => {
    const description = convertTranslation(
      values.description || initialTranslations
    );
    const imageUrlOrCanvas = waitingListImageUrl;
    const keywordsValues = convertKeywordsTranslation(values.keywords);

    const keywords = keywordsValues.map((el) => {
      return {
        ...el,
        text: !!el.text.length
          ? el.text.join(',')
          : keywordsValues.find((key) => key.lang === 'en')?.text.join(',') ||
            '',
      };
    });

    const experienceName = convertTranslation(
      values.experienceName || initialTranslations
    );
    const price = values.billingMode === BillingMode.FREE ? '0' : values.price;
    const voucherExpirationTimeOutInMs = initialDuration
      ? initialDuration * timeUnitOptions[initialUnit].value
      : 0;

    const maxNumberOfSeatsPerPosition =
      values.positionsDistributionMode === PositionsDistributionMode.RANDOM
        ? values?.fixedNumberOfSeatsPerPosition
        : values?.maxNumberOfSeatsPerPosition;

    if (wishList?.waitingListId) {
      setIsSubmitting(true);

      updateWishList(
        {
          waitingListId: wishList?.waitingListId,

          body: {
            ...wishList,
            ...values,
            billingVariables: {
              ...values.billingVariables,
              distributionRate: percentageToDecimalString(
                values.billingVariables.distributionRate
              ),
            },
            maxNumberOfSeatsPerPosition,
            price,
            description,
            keywords,
            experienceName,
            eventId: wishList?.event.eventId,
            waitingListId: wishList?.waitingListId,
            voucherExpirationTimeOutInMs,
          },
        },
        {
          onSuccess: async () => {
            if (imageUrlOrCanvas && typeof imageUrlOrCanvas !== 'string') {
              await requestOneTimeUpload({
                fileName: 'file.png',
              }).then(async (res) => {
                if (res.fileId) {
                  const formFile = new FormData();
                  const blob = (await new Promise((resolve) =>
                    imageUrlOrCanvas.toBlob(resolve)
                  )) as Blob;
                  formFile.append('file', blob);
                  await uploadFile(formFile, res.path.slice(20, 52)).then(
                    () => {
                      updateWishListImage(
                        {
                          waitingListId: wishList?.waitingListId,
                          body: {
                            fileId: res?.fileId,
                          },
                        },
                        {
                          onSuccess: () => {
                            setIsSubmitting(false);
                          },
                        }
                      );
                    }
                  );
                }
              });
            }

            if (termsAndConditionsUrl?.[0]?.originFileObj) {
              setIsSubmitting(true);
              const requestOneTimeUploadResponse = await uploadImage({
                imageUrl: termsAndConditionsUrl[0].originFileObj,
                fileName: encodeURIComponent(
                  termsAndConditionsUrl[0].originFileObj?.name
                ),
              });
              updateWishListTC(
                {
                  waitingListId: wishList?.waitingListId,
                  body: {
                    fileId: requestOneTimeUploadResponse?.fileId,
                    expirationDate: new Date().toISOString(),
                  },
                },
                {
                  onSuccess: () => setIsSubmitting(false),
                }
              );
            }

            if (saveAsDraft) {
              updateWLToDraft(
                { waitingListId: wishList?.waitingListId },
                {
                  onSuccess: () =>
                    navigate(`/${routes.admin}/${routes.wishLists}`),
                }
              );
            } else {
              next();
            }
            notification.success({
              message: t('notification_success_wl_updated'),
            });
            queryClient.invalidateQueries(
              fanGroupOwnerWaitingListsKeys.detail(wishListId)
            );
            queryClient.invalidateQueries(
              fanGroupOwnerWaitingListNames.allPerFanGroup(fanGroup?.id ?? '')
            );
            if (isEdit) {
              navigate(
                `/${routes.admin}/${routes.wishLists}/${wishList?.waitingListId}`
              );
            }
          },
          onError: (error) => {
            notification.error({
              message: t('notification_error_wl_updated'),
              description: error.response?.data?.message || error.message,
            });
            setIsSubmitting(false);
          },
        }
      );
    }
  };

  const approvalModeOptions = [
    { label: t('wl_settings_approval'), value: InvitationApprovalMode.DEFAULT },
    { label: t('wl_settings_no-approval'), value: InvitationApprovalMode.NONE },
  ];

  const assignationModeOptions = [
    {
      label: t('wl_settings_assignation_guest_rsvp'),
      value: AssignationMode.GUEST_RSVP,
    },
    {
      label: t('wl_settings_assignation_host_rsvp'),
      value: AssignationMode.HOST_RSVP,
    },
  ];

  // This will hold reference to `<Select>`
  const selectRef = useRef(null);

  const setTicketCategory = () => {
    if (selectSearchValue) {
      setValue('name', selectSearchValue);
      trigger('name');
    }
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setTicketCategory();
    }
  };

  const guestManagementEnabled =
    invitationParameters?.invitationMode === InvitationMode.ENABLED;

  const draftStatuses = [
    WaitingListStatusEnum.SETUP,
    WaitingListStatusEnum.DRAFT,
  ];

  const isDraft = wishList && draftStatuses.includes(wishList?.status);

  const handleRemoveImage = () => {
    if (waitingListImageUrl) {
      setValue('waitingListImageUrl', null);
    } else {
      deleteWishListImage(
        { waitingListId: wishListId },
        {
          onSuccess: () => {
            notification.success({
              message: t('notification_success_image_removed'),
            });
            setValue('waitingListImageUrl', null);
          },
        }
      );
    }
  };

  const totalSeatsPrice = getTotalSeatPrice(
    parseFloat(price) || 0,
    billingVariables ?? wishList?.billingVariables,
    0
  );

  return (
    <>
      <Space
        size="large"
        direction="vertical"
        style={{ width: '100%', paddingBottom: 40 }}
      >
        <p style={{ color: baseColor600 }}>{t('configure_fg_title')}</p>
        <FormProvider {...methods}>
          <Form
            layout="vertical"
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
            disabled={isSubmitting}
          >
            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {t('admin_event')}
            </Paragraph>
            {wishList?.event && (
              <List
                style={{ marginBottom: 24 }}
                bordered
                itemLayout="horizontal"
                dataSource={[wishList?.event]}
                renderItem={(item) => {
                  return (
                    <List.Item
                      key={item?.eventId}
                      actions={[
                        <ConfigProvider
                          theme={{
                            token: {
                              colorText: primaryColor,
                            },
                          }}
                        >
                          <Button
                            type="text"
                            color={token.colorPrimary}
                            icon={<EyeOutlined rev={undefined} />}
                            onClick={() => setEventDescriptionShown(true)}
                          >
                            {t('show_description_button')}
                          </Button>
                        </ConfigProvider>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          <img
                            width={160}
                            height={80}
                            alt="logo"
                            src={item?.eventImageUrl ?? item?.venueImageUrl}
                            style={{ borderRadius: 4 }}
                          />
                        }
                        title={item?.eventName}
                        style={{ alignItems: 'center' }}
                        description={item?.venueName}
                      />
                    </List.Item>
                  );
                }}
              />
            )}
            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {t('fgc_tab-general')}
            </Paragraph>

            <Space
              direction="vertical"
              size="small"
              style={{ marginBottom: 24, width: '100%' }}
            >
              <Text>{t('admin_fan-groups_preview-image')}</Text>
              <Image
                width={'70%'}
                src={
                  typeof waitingListImageUrl === 'string'
                    ? waitingListImageUrl
                    : waitingListImageUrl?.toDataURL() ||
                      wishList?.event.eventImageUrl ||
                      wishList?.event.venueImageUrl ||
                      wishList?.waitingListImageUrl
                }
                style={{
                  // backgroundColor: baseColor200,
                  objectFit: 'cover',
                  borderRadius: 12,
                  maxHeight: 500,
                }}
                preview={false}
              />
            </Space>

            <Row align="middle">
              <Col xs={24} md={24} xl={12}>
                <Form.Item
                  label={
                    <div>
                      {t('wl_set_edit-experience-name')}{' '}
                      <Tooltip title={t('wl_set_edit-experience-name-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name={`experienceName.${currentLanguage}`}
                >
                  <Controller
                    control={control}
                    name={`experienceName.${currentLanguage}`}
                    render={({ field }) => <Input {...field} size="middle" />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Space
              direction="horizontal"
              size="middle"
              style={{ marginBottom: 24, display: 'flex' }}
            >
              <Form.Item label={null} style={{ margin: 0 }} name="highlighted">
                <Controller
                  control={control}
                  name="highlighted"
                  render={({ field }) => (
                    <Switch
                      size="default"
                      checkedChildren={<CheckOutlined rev={undefined} />}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </Form.Item>
              <Text>{t('wlc_highlited_label')}</Text>
            </Space>
            <Row gutter={18} align="middle">
              <Col xs={24} md={12} xl={8}>
                <Form.Item
                  name="name"
                  label={
                    <div>
                      {t('wl_set_seat-category')}{' '}
                      <Tooltip title={t('wl_set_name-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  validateStatus={errors.name && errors.name.message && 'error'}
                  help={errors.name && t('general_label_required')}
                  required
                >
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <Select
                        {...field}
                        style={{ width: 300 }}
                        defaultActiveFirstOption={false}
                        onBlur={() => {
                          field.onBlur();
                          setTicketCategory();
                        }}
                        showSearch
                        options={wishListNamesOptions}
                        size="middle"
                        onSearch={setSelectSearchValue}
                        onSelect={(_, option) => {
                          setSelectSearchValue(option?.value);
                        }}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="waitingListImageUrl"
              label={t('wish_list_settings_image')}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              status="success"
              help={errors.waitingListImageUrl?.message}
              validateStatus={
                errors.waitingListImageUrl?.message ? 'error' : undefined
              }
            >
              <ImageCropper
                imageOrCanvas={waitingListImageUrl ?? ''}
                handleRemoveImage={handleRemoveImage}
                handleImageChange={(img) =>
                  setValue('waitingListImageUrl', img)
                }
                handleReset={() => {
                  setValue(
                    'waitingListImageUrl',
                    initialValues.waitingListImageUrl
                  );
                }}
                formItemProps={{
                  name: 'waitingListImageUrl',
                }}
              />
            </Form.Item>

            <Row gutter={18}>
              <Col xs={24} md={24} xl={16}>
                <Form.Item
                  name={`description.${currentLanguage}`}
                  label={
                    <div>
                      {t('wl_set_edit-description')}{' '}
                      <Tooltip title={t('wl_set_edit-description-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                >
                  <Controller
                    control={control}
                    name={`description.${currentLanguage}`}
                    render={({ field: { onChange, ...field } }) => {
                      return !editorShown ? (
                        <StyledText
                          text={field.value ?? ''}
                          onClick={() => setEditorShown(true)}
                        />
                      ) : (
                        <Editor
                          onEditorChange={onChange}
                          width="100%"
                          height={320}
                          {...field}
                        />
                      );
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={18}>
              <Col xs={24} md={16} xl={12}>
                <Form.Item
                  label={
                    <div>
                      {t('wl_set_keywords')}{' '}
                      <Tooltip title={t('wl_set_keywords-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name={`keywords.${currentLanguage}`}
                >
                  <Controller
                    control={control}
                    name={`keywords.${currentLanguage}`}
                    render={({ field }) => (
                      <Select mode="tags" size="middle" {...field} />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            {lookEntity?.properties?.waitingListAccessMode?.length === 2 && (
              <>
                <Paragraph
                  strong
                  style={{ color: baseColor900, marginBottom: 24 }}
                >
                  {t('wl_access_mode-title')}{' '}
                  <Tooltip title={t('mwl_set_help-noedit')}>
                    <InfoCircleOutlined
                      rev={undefined}
                      style={{ color: baseColor600 }}
                    />
                  </Tooltip>
                </Paragraph>
                <Form.Item name="accessMode">
                  <Controller
                    control={control}
                    name="accessMode"
                    render={({ field }) => (
                      <Radio.Group
                        {...field}
                        defaultValue={initialValues.accessMode}
                      >
                        {accessModeOptions.map((el) => (
                          <Radio
                            key={el.value}
                            value={el.value}
                            disabled={!isDraft}
                          >
                            {t(el.label)}
                          </Radio>
                        ))}
                      </Radio.Group>
                    )}
                  />
                </Form.Item>
              </>
            )}
            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {t('wl_pricing-title')}{' '}
              <Tooltip title={t('mwl_set_help-noedit')}>
                <InfoCircleOutlined
                  rev={undefined}
                  style={{ color: baseColor600 }}
                />
              </Tooltip>
            </Paragraph>

            <Form.Item name="billingMode">
              <Controller
                control={control}
                name="billingMode"
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    defaultValue={initialValues.billingMode}
                  >
                    {wlBillingModeOptions.map((el) => (
                      <Radio
                        key={el.value}
                        value={el.value}
                        disabled={!isDraft}
                      >
                        {t(el.label)}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              />
            </Form.Item>
            {billingMode === BillingMode.FREE && (
              <Row gutter={20} align="middle">
                <Col xs={24} md={16} xl={6}>
                  <Form.Item
                    label={<div>{t('wl_set_ticket-original-value')} </div>}
                    name="originalPrice"
                  >
                    <Controller
                      control={control}
                      name="originalPrice"
                      render={({ field: { onChange, ...restField } }) => (
                        <InputNumber
                          {...restField}
                          onChange={(v) => {
                            v === null
                              ? setValue('originalPrice', 0)
                              : onChange(v);
                          }}
                          min={0}
                          step="0.01"
                          defaultValue={0}
                          stringMode
                          addonAfter={wishList?.event.venueCurrency}
                          size="middle"
                          disabled={!isDraft}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={16} xl={12}>
                  <Space
                    direction="horizontal"
                    size="middle"
                    style={{ marginBottom: 24, display: 'flex' }}
                  >
                    <Form.Item
                      label={t('wlc_price_visibility_label')}
                      style={{ margin: 0 }}
                      name="showFreePrice"
                    >
                      <Controller
                        control={control}
                        name="showFreePrice"
                        render={({ field }) => (
                          <Switch
                            size="default"
                            checkedChildren={<CheckOutlined rev={undefined} />}
                            {...field}
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Space>
                </Col>
              </Row>
            )}
            {billingMode === BillingMode.FAIR_PRICE && (
              <>
                <Row gutter={18} align="middle">
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_ticket-value')}{' '}
                          <Tooltip title={t('wl_set_ticket-value-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="price"
                    >
                      <Controller
                        control={control}
                        name="price"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min="0"
                            step="0.01"
                            stringMode
                            addonAfter={wishList?.event.venueCurrency}
                            size="middle"
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_ticket-original-value')}{' '}
                          <Tooltip
                            title={t('wl_set_ticket-original-value-help')}
                          >
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="originalPrice"
                    >
                      <Controller
                        control={control}
                        name="originalPrice"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            step="0.01"
                            defaultValue={0}
                            stringMode
                            addonAfter={wishList?.event.venueCurrency}
                            size="middle"
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16} xl={4}>
                    <Space
                      direction="horizontal"
                      size="middle"
                      style={{ marginBottom: 24, display: 'flex' }}
                    >
                      <Form.Item
                        label={
                          <div>
                            {t('wl_set_prepaid-fee')}{' '}
                            <Tooltip title={t('wl_set_prepaid-fee-help')}>
                              <InfoCircleOutlined
                                rev={undefined}
                                style={{ color: baseColor600 }}
                              />
                            </Tooltip>
                          </div>
                        }
                        // wl_set_prepaid-fee-help
                        style={{ margin: 0 }}
                        name="prepaidFee"
                      >
                        <Controller
                          control={control}
                          name="prepaidFee"
                          render={({ field }) => (
                            <Switch
                              size="default"
                              checkedChildren={
                                <CheckOutlined rev={undefined} />
                              }
                              {...field}
                              disabled={!isDraft}
                            />
                          )}
                        />
                      </Form.Item>
                    </Space>
                  </Col>
                </Row>
                <Row gutter={18} align="middle">
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_min_distribution_fee')}{' '}
                          <Tooltip
                            title={t('wl_set_min_distribution_fee-help')}
                          >
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="billingVariables.minDistributionFee"
                    >
                      <Controller
                        control={control}
                        name="billingVariables.minDistributionFee"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            step="0.01"
                            defaultValue="0"
                            stringMode
                            addonAfter={wishList?.event.venueCurrency}
                            size="middle"
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_max_distribution_fee')}{' '}
                          <Tooltip
                            title={t('wl_set_max_distribution_fee-help')}
                          >
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="billingVariables.maxDistributionFee"
                    >
                      <Controller
                        control={control}
                        name="billingVariables.maxDistributionFee"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min={0}
                            step="0.01"
                            defaultValue="0"
                            stringMode
                            addonAfter={wishList?.event.venueCurrency}
                            size="middle"
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_distribution_rate')}{' '}
                          <Tooltip title={t('wl_set_distribution_rate-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="billingVariables.distributionRate"
                    >
                      <Controller
                        control={control}
                        name="billingVariables.distributionRate"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            min="0"
                            max="100"
                            formatter={(value) => `${value}%`}
                            // backend expects a number without the %
                            // frontend should display the % sign
                            parser={(value) => value!.replace('%', '')}
                            stringMode
                            size="middle"
                            disabled={!isDraft}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18} align="middle">
                  <Col xs={24} md={16} xl={4}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_total-price')}{' '}
                          <Tooltip title={t('wl_set_total-price-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="totalSeatPrice"
                    >
                      <Controller
                        control={control}
                        name="totalSeatPrice"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            value={totalSeatsPrice}
                            step="0.01"
                            stringMode
                            addonAfter={wishList?.event.venueCurrency}
                            size="middle"
                            disabled={!isDraft}
                            readOnly
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {t('wl_set_positions_distribution_mode')}
            </Paragraph>

            <Space
              direction="horizontal"
              size="middle"
              style={{ marginBottom: 24, display: 'flex' }}
            >
              <Form.Item
                label={null}
                style={{ margin: 0 }}
                name="directSales"
                valuePropName="checked"
              >
                <Controller
                  control={control}
                  name="directSales"
                  render={({ field }) => (
                    <Switch
                      size="default"
                      checkedChildren={<CheckOutlined rev={undefined} />}
                      {...field}
                      checked={field.value}
                    />
                  )}
                />
              </Form.Item>

              <Text>
                {t('wl_set_direct-sales')}{' '}
                <Tooltip title={t('wl_set_direct-sales-help')}>
                  <InfoCircleOutlined
                    rev={undefined}
                    style={{ color: baseColor600 }}
                  />
                </Tooltip>
              </Text>
            </Space>
            <Row
              gutter={18}
              align="middle"
              style={{ alignItems: 'flex-start' }}
            >
              <Col xs={24} md={16} xl={5}>
                <Form.Item
                  label={
                    <div>
                      {t('general_type')}{' '}
                      <Tooltip
                        title={t('wl_set_positions_distribution_mode-help')}
                      >
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name="positionsDistributionMode"
                >
                  <Controller
                    control={control}
                    name="positionsDistributionMode"
                    render={({ field }) => (
                      <Select
                        {...field}
                        size="middle"
                        options={positionDistributionOptions}
                        onSelect={(v) => {
                          trigger('displayMode');
                          setValue('termsAndConditionFileURL', '');
                          setTermsAndConditionsUrl([]);
                          return v;
                        }}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={16} xl={5}>
                <Form.Item
                  label={
                    <div>
                      {t('display_mode_label')}{' '}
                      <Tooltip title={t('display_mode_label-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name="displayMode"
                  validateStatus={
                    errors.displayMode && errors.displayMode.message && 'error'
                  }
                  help={errors.displayMode && errors.displayMode.message}
                >
                  <Controller
                    control={control}
                    name="displayMode"
                    render={({ field }) => (
                      <Select
                        {...field}
                        size="middle"
                        options={displayModeOptions}
                        onSelect={() => {
                          trigger('displayMode');
                        }}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18} align="middle">
              <Col xs={24} md={16} xl={5}>
                <Form.Item
                  label={t('terms_and_conditions_file_label')}
                  validateStatus={
                    errors.termsAndConditionFileURL &&
                    errors.termsAndConditionFileURL?.message &&
                    'error'
                  }
                  help={
                    errors.termsAndConditionFileURL &&
                    t('general_label_required')
                  }
                  name="termsAndConditionFileURL"
                >
                  <Controller
                    control={control}
                    name="termsAndConditionFileURL"
                    render={({ field: { onChange, ...field } }) => (
                      <Input
                        {...field}
                        size="middle"
                        value={
                          termsAndConditionsUrl?.[0]?.originFileObj?.name ||
                          initialValues.termsAndConditionFileURL
                        }
                        addonAfter={
                          <Space style={{ cursor: 'pointer' }}>
                            <Upload
                              accept=".pdf"
                              showUploadList={false}
                              onChange={(v) => {
                                setTermsAndConditionsUrl(v.fileList);
                                setValue(
                                  'termsAndConditionFileURL',
                                  v.file.name
                                );
                              }}
                              multiple={false}
                            >
                              {termsAndConditionsUrl?.[0]?.originFileObj?.name
                                ? t('admin_item_select')
                                : t('general_upload-btn')}
                            </Upload>
                          </Space>
                        }
                      />
                    )}
                  />
                </Form.Item>
              </Col>
              {initialValues?.termsAndConditionFileURL && (
                <Col xs={4} md={4} xl={4}>
                  <Form.Item label="hidden">
                    <Link
                      to={initialValues.termsAndConditionFileURL}
                      target="_blank"
                    >
                      <Button
                        icon={<DownloadOutlined rev={undefined} />}
                        type="link"
                      >
                        {t('general_download-btn')}
                      </Button>
                    </Link>
                  </Form.Item>
                </Col>
              )}

              <Col xs={24} md={16} xl={5}>
                <Form.Item label="hidden">
                  <Text style={{ color: baseColor600 }}>
                    {t('terns_and_conds_tip_text')}
                  </Text>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              <Col xs={24} md={16} xl={5}>
                <Form.Item
                  label={
                    <div>
                      {t('confirm_rsvp_time_label')}{' '}
                      <Tooltip title={t('wl_set_confirm-seat-to-help')}>
                        <InfoCircleOutlined
                          rev={undefined}
                          style={{ color: baseColor600 }}
                        />
                      </Tooltip>
                    </div>
                  }
                  name="voucherExpirationTimeOutInMs"
                >
                  <Controller
                    disabled={guestManagementEnabled}
                    control={control}
                    name="voucherExpirationTimeOutInMs"
                    defaultValue={wishList?.voucherExpirationTimeOutInMs}
                    render={({ field: { value, ...field } }) => (
                      <InputNumber
                        {...field}
                        min={0}
                        type="number"
                        onChange={handleInputChange}
                        value={
                          initialUnit === unit ? initialDuration : duration
                        }
                        addonAfter={
                          <Select
                            onChange={handleUnitChange}
                            value={unit}
                            style={{ minWidth: 90 }}
                            disabled={guestManagementEnabled}
                            options={Object.keys(timeUnitOptions).map(
                              (unit) => ({
                                label: t(unit),
                                value: unit,
                              })
                            )}
                          />
                        }
                        size="middle"
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={18}>
              {positionsDistributionMode ===
                PositionsDistributionMode.RANDOM && (
                <Col xs={24} md={16} xl={3}>
                  <Form.Item
                    label={
                      <div>
                        {t('wl_set_fixed-seats')}{' '}
                        <Tooltip title={t('wl_set_fixed-seats-help')}>
                          <InfoCircleOutlined
                            rev={undefined}
                            style={{ color: baseColor600 }}
                          />
                        </Tooltip>
                      </div>
                    }
                    name="fixedNumberOfSeatsPerPosition"
                    validateStatus={
                      errors.fixedNumberOfSeatsPerPosition &&
                      errors.fixedNumberOfSeatsPerPosition?.message &&
                      'error'
                    }
                    help={
                      errors.fixedNumberOfSeatsPerPosition &&
                      t('general_label_required')
                    }
                  >
                    <Controller
                      control={control}
                      name="fixedNumberOfSeatsPerPosition"
                      render={({ field }) => (
                        <InputNumber
                          {...field}
                          type="number"
                          size="middle"
                          min={1}
                        />
                      )}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col xs={24} md={16} xl={8}>
                <Form.Item
                  label={
                    <div>
                      {t('wl_set_participation_end_date')}{' '}
                      <InfoCircleOutlined
                        rev={undefined}
                        style={{ color: baseColor600 }}
                      />
                    </div>
                  }
                  name="participationEndDate"
                  validateStatus={
                    errors.participationEndDate &&
                    errors.participationEndDate?.message &&
                    'error'
                  }
                  help={
                    errors.participationEndDate && t('general_label_required')
                  }
                >
                  <Controller
                    control={control}
                    name="participationEndDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        size="middle"
                        style={{ width: '100%' }}
                        showTime={{
                          defaultValue: dayjs('00:00', 'HH:mm'),
                          format: 'HH:mm',
                        }}
                        format={'YYYY-MM-DD HH:mm'}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            {positionsDistributionMode !== PositionsDistributionMode.RANDOM &&
              !guestManagementEnabled && (
                <Row gutter={18}>
                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {t('min_seats_per_fan')}{' '}
                          <Tooltip title={t('wl_set_min-seats-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="minNumberOfSeatsPerPosition"
                      validateStatus={
                        errors.minNumberOfSeatsPerPosition &&
                        errors.minNumberOfSeatsPerPosition?.message &&
                        'error'
                      }
                      help={
                        errors.minNumberOfSeatsPerPosition &&
                        t('general_label_required')
                      }
                    >
                      <Controller
                        disabled={guestManagementEnabled}
                        control={control}
                        name="minNumberOfSeatsPerPosition"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={1}
                            max={maxNumberOfSeatsPerPosition}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {t('max_seats_per_fan')}{' '}
                          <Tooltip title={t('wl_set_max-seats-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="maxNumberOfSeatsPerPosition"
                      validateStatus={
                        errors.maxNumberOfSeatsPerPosition &&
                        errors.maxNumberOfSeatsPerPosition?.message &&
                        'error'
                      }
                      help={
                        errors.maxNumberOfSeatsPerPosition &&
                        t('general_label_required')
                      }
                    >
                      <Controller
                        disabled={guestManagementEnabled}
                        control={control}
                        name="maxNumberOfSeatsPerPosition"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={minNumberOfSeatsPerPosition}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}

            <Paragraph strong style={{ color: baseColor900, marginBottom: 24 }}>
              {/* // Guest management parameters */}
              {t('wl_settings_guest_management_params')}
            </Paragraph>

            <Space
              direction="horizontal"
              size="middle"
              style={{ marginBottom: 24, display: 'flex' }}
            >
              <Form.Item
                label={null}
                style={{ margin: 0 }}
                name="invitationParameters.invitationMode"
                valuePropName="checked"
              >
                <Controller
                  control={control}
                  name="invitationParameters.invitationMode"
                  render={({ field }) => {
                    return (
                      <Switch
                        size="default"
                        checkedChildren={<CheckOutlined rev={undefined} />}
                        {...field}
                        checked={field.value === InvitationMode.ENABLED}
                        onChange={(checked) => {
                          setValue(
                            'invitationParameters.invitationMode',
                            invitationModeMatch.get(checked)
                          );
                          if (checked) {
                            setInitialDuration(0);
                          } else {
                            setInitialDuration(48);
                            setUnit('hours');
                          }
                        }}
                      />
                    );
                  }}
                />
              </Form.Item>
              {/* Enable guest management */}
              <Text>{t('wl_settings_guest_management_enable')}</Text>
            </Space>
            {invitationParameters?.invitationMode ===
              InvitationMode.ENABLED && (
              <>
                <Row gutter={18} align="middle">
                  <Col xs={24} md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {/* Approval Mode */}
                          {t('wl_settings_approval_mode')}{' '}
                          <Tooltip
                            title={t('wl_set_positions_distribution_mode-help')}
                          >
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="invitationParameters.approvalMode"
                    >
                      <Controller
                        control={control}
                        name="invitationParameters.approvalMode"
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="middle"
                            options={approvalModeOptions}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {/* Assignation Mode{' '} */}
                          {t('wl_settings_assignation_mode')}{' '}
                          <Tooltip
                            title={t('wl_settings_assignation_mode-help')}
                          >
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="invitationParameters.assignationMode"
                    >
                      <Controller
                        control={control}
                        name="invitationParameters.assignationMode"
                        render={({ field }) => (
                          <Select
                            {...field}
                            size="middle"
                            options={assignationModeOptions}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {/* Min. tickets/Host */}
                          {t('min_tickets_per_host')}{' '}
                          <Tooltip title={t('min_tickets_per_host-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="invitationParameters.minNumberOfInvitations"
                      validateStatus={
                        errors.invitationParameters?.minNumberOfInvitations
                          ?.message && 'error'
                      }
                      help={
                        errors.invitationParameters?.minNumberOfInvitations &&
                        t('general_label_required')
                      }
                    >
                      <Controller
                        control={control}
                        name="invitationParameters.minNumberOfInvitations"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={1}
                            max={maxNumberOfInvitations}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {/* Max. tickets/Host{' '} */}
                          {t('max_tickets_per_host')}{' '}
                          <Tooltip title={t('max_tickets_per_host-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="invitationParameters.maxNumberOfInvitations"
                      validateStatus={
                        errors.invitationParameters?.maxNumberOfInvitations
                          ?.message && 'error'
                      }
                      help={t(
                        errors.invitationParameters?.maxNumberOfInvitations
                          ?.message
                      )}
                    >
                      <Controller
                        control={control}
                        name="invitationParameters.maxNumberOfInvitations"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={minNumberOfInvitations}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_min-seats-guest')}{' '}
                          <Tooltip title={t('wl_set_min-seats-guest-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="minNumberOfSeatsPerPosition"
                      validateStatus={
                        errors.minNumberOfSeatsPerPosition &&
                        errors.minNumberOfSeatsPerPosition?.message &&
                        'error'
                      }
                      help={
                        errors.minNumberOfSeatsPerPosition &&
                        t('general_label_required')
                      }
                    >
                      <Controller
                        control={control}
                        name="minNumberOfSeatsPerPosition"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={1}
                            max={maxNumberOfSeatsPerPosition}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={16} xl={5}>
                    <Form.Item
                      label={
                        <div>
                          {t('wl_set_max-seats-guest')}{' '}
                          <Tooltip title={t('wl_set_max-seats-guest-help')}>
                            <InfoCircleOutlined
                              rev={undefined}
                              style={{ color: baseColor600 }}
                            />
                          </Tooltip>
                        </div>
                      }
                      name="maxNumberOfSeatsPerPosition"
                      validateStatus={
                        errors.maxNumberOfSeatsPerPosition &&
                        errors.maxNumberOfSeatsPerPosition?.message &&
                        'error'
                      }
                      help={
                        errors.maxNumberOfSeatsPerPosition &&
                        t('general_label_required')
                      }
                    >
                      <Controller
                        control={control}
                        name="maxNumberOfSeatsPerPosition"
                        render={({ field }) => (
                          <InputNumber
                            {...field}
                            type="number"
                            size="middle"
                            min={minNumberOfSeatsPerPosition}
                          />
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Space
                  direction="horizontal"
                  size="middle"
                  style={{ marginBottom: 24, display: 'flex' }}
                >
                  <Form.Item
                    label={null}
                    style={{ margin: 0 }}
                    name="invitationParameters.specialGuestEnabled"
                    valuePropName="checked"
                  >
                    <Controller
                      control={control}
                      name="invitationParameters.specialGuestEnabled"
                      render={({ field }) => {
                        return (
                          <>
                            <Switch
                              size="default"
                              checkedChildren={
                                <CheckOutlined rev={undefined} />
                              }
                              {...field}
                              checked={field.value}
                            />{' '}
                          </>
                        );
                      }}
                    />
                  </Form.Item>
                  <Text>
                    {t('wl_settings_special_guest_enable')}{' '}
                    <Tooltip title={t('wl_settings_special_guest_enable-help')}>
                      <InfoCircleOutlined
                        rev={undefined}
                        style={{ color: baseColor600 }}
                      />
                    </Tooltip>
                  </Text>
                  {/* Enable special guest */}
                </Space>
              </>
            )}

            <Footer
              isEdit={isEdit}
              handlePrevStep={prev}
              saveAsDraft={() => {
                setSaveAsDraft(true);
                onSubmit(getValues());
              }}
              cancelButton={
                <Button
                  disabled={!Object.keys(dirtyFields).length}
                  size="middle"
                  type="default"
                  danger
                  icon={<CloseOutlined rev={undefined} />}
                  onClick={() => reset(initialValues)}
                >
                  {t('button_text_cancel')}
                </Button>
              }
              isLoading={isSubmitting}
            >
              <Segmented
                size="middle"
                options={languages}
                value={currentLanguage}
                onChange={setCurrentLanguage}
              />
            </Footer>
          </Form>
        </FormProvider>
      </Space>
      <Spin
        fullscreen
        spinning={wishListIsLoading || isSubmitting}
        size="large"
        tip={t('fg_creation_loader_tip')}
      />
      <EventDetailedInfoModal
        isModalOpen={eventDescriptionShown}
        handleCancel={() => setEventDescriptionShown(false)}
        event={wishList?.event ?? null}
        // handleOnSelect={handleSelectEvent}
        isEventSet={!!wishList?.event}
      />
    </>
  );
}
