import React, { useEffect } from 'react';
import {
  getSessionToken,
  queryClient,
  useVerifyOauth,
} from '@seaters-app/data-access';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Container } from '@seaters-app/ui';
import { routes } from '@seaters-app/constants';
import { Navigate, useParams } from 'react-router-dom';
import {
  SESSION_STORAGE_ALLOWED_GROUPS,
  LOCAL_STORAGE_SLUG,
  LOCAL_STORAGE_STRIPE_DEEP_LINK,
  LookEntity,
} from '@seaters-app/constants';
import { Spin, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '@seaters-app/ui';

const VerifyAuthentication: React.FC = () => {
  const auth = getSessionToken();
  const { t } = useTranslation();

  const slug = localStorage.getItem(LOCAL_STORAGE_SLUG);
  const searchParams = new URLSearchParams(window.location.search);

  const { provider } = useParams();

  const code = searchParams.get('code');

  const error = searchParams.get('error');

  const fanGroupData: LookEntity | undefined = queryClient.getQueryData([
    'users',
    'look',
  ]);

  const { mutate: verifyOauth, isLoading } = useVerifyOauth({
    provider: provider || '',
    authorizationCode: code || '',
    fangroupId: fanGroupData?.fanGroupId || '',
  });

  const handleVerify = () => {
    verifyOauth();
  };

  let origin: string | undefined = undefined;
  let stripeDeepLink: string | undefined = undefined;
  let wlId = null;

  if (provider === 'kpn-b2c') {
    const state = searchParams.get('state');

    const decodedState = state ? atob(state) : null;

    const splitted = decodedState?.split('&');

    origin = splitted
      ?.find((item) => item.startsWith('origin='))
      ?.slice('origin='.length);

    wlId =
      splitted
        ?.find((item) => item.startsWith('wlId='))
        ?.slice('wlId='.length)
        .trim() ?? null;

    if (origin && origin !== localStorage.getItem(LOCAL_STORAGE_SLUG)) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, origin);
    }

    const allowedFangroup = splitted
      ?.find((item) => item.startsWith('allowedGroups='))
      ?.slice('allowedGroups='.length);

    if (
      allowedFangroup &&
      allowedFangroup !== sessionStorage.getItem(SESSION_STORAGE_ALLOWED_GROUPS)
    ) {
      sessionStorage.setItem(SESSION_STORAGE_ALLOWED_GROUPS, allowedFangroup);
    }

    stripeDeepLink = splitted
      ?.find((item) => item.startsWith('stripeDeepLink='))
      ?.slice('stripeDeepLink='.length);

    if (stripeDeepLink) {
      localStorage.setItem(LOCAL_STORAGE_STRIPE_DEEP_LINK, stripeDeepLink);
    }
  }

  useEffect(() => {
    if (!isLoading && provider && code && fanGroupData?.fanGroupId && !auth) {
      handleVerify();
    }
  }, []);

  useEffect(() => {
    if (!slug && origin) {
      localStorage.setItem(LOCAL_STORAGE_SLUG, origin);
      handleVerify();
    }
  }, []);

  return auth ? (
    <Navigate
      to={`/${
        wlId && slug ? `${slug}/${routes.waitingList}/${wlId}` : slug ?? origin
      }`}
    />
  ) : error ? (
    <Container>
      <Alert
        type="error"
        message={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {t(`sso_${error}`)}
            <Button
              icon={<ArrowLeftOutlined />}
              style={{
                alignSelf: 'flex-end',
              }}
              onClick={() => window.history.back()}
            >
              {t('group_menu_back')}
            </Button>
          </div>
        }
      />
    </Container>
  ) : (
    <Spin
      tip={t('waitinglist_braintree-modal-loading')}
      size="large"
      style={{ margin: '24px 0' }}
    >
      <div className="content" />
    </Spin>
  );
};

export default VerifyAuthentication;
