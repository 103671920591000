import React, { ReactElement, useEffect, useState } from 'react';

import { Modal, ModalProps, notification, Spin, Steps } from 'antd';
import { PersonalInfoForm } from './PersonalInfoForm';
import { useParams } from 'react-router-dom';
import {
  useFetchWaitingListSurveyInstances,
  useUpdateFanAttendeesInfo,
} from '@seaters-app/data-access';
import {
  AttendeesInfoFormValidator,
  SurveyExtensionPoint,
} from '@seaters-app/constants';
import { StepContentProps, StepItemIdEnum, StepType } from './types';
import { SurveyForm } from './SurveyForm';
import { useFormSteps } from './hooks/useFormSteps';
import { useTranslation } from 'react-i18next';
import { AttendeeInfoStep } from './AttendeeInfoStep';
import { PaymentStep } from './PaymentStep';

export interface CheckoutModalProps extends ModalProps {
  children?: ReactElement;
  isDisabled?: boolean;
  isLoading?: boolean;
}

export function CheckoutModal({ open, ...props }: CheckoutModalProps) {
  const { t } = useTranslation();
  const { waitingListId = '' } = useParams();

  const { data: surveyInstances } = useFetchWaitingListSurveyInstances({
    waitinglist_id: waitingListId,
    extension_point: SurveyExtensionPoint.AT_CHECKOUT,
  });

  const surveyStep: StepType<StepItemIdEnum> = {
    key: StepItemIdEnum.SURVEY_FORM,
    title: t('survey_title_text'),
    content: (props) => (
      <SurveyForm
        {...props}
        ticketsAmount={waitingList?.position.numberOfSeats}
        surveyInstances={surveyInstances}
      />
    ),
    submitText: t('finish_button_text'),
    orderIndex: 2,
  };

  const paymentStep: StepType<StepItemIdEnum> = {
    key: StepItemIdEnum.PAYMENT_FORM,
    title: t('payment_modal_title'),
    content: (props) => {
      return <PaymentStep {...props} />;
    },
    submitText: t('purchase_button_text'),
    orderIndex: 4,
  };

  const personalInfoStep: StepType<StepItemIdEnum> = {
    key: StepItemIdEnum.PERSONAL_INFO_FORM,
    title: t('checkout_billinginfo_steptitle'),
    content: (props) => {
      return <PersonalInfoForm {...props} />;
    },
    submitText: t('button_text_next'),
    orderIndex: 0,
  };

  const attendeeInfoStep: StepType<StepItemIdEnum> = {
    key: StepItemIdEnum.ATTENDEE_INFO,
    title: t('checkout_requiredinfo_steptitle'),
    content: (props) => {
      return <AttendeeInfoStep currentStep={currentStep} {...props} />;
    },
    submitText: t('button_text_next'),
    orderIndex: 3,
  };

  const steps = [personalInfoStep, attendeeInfoStep, paymentStep, surveyStep];

  const [stepsVisibility, setStepsVisibility] = useState<{
    [key in StepItemIdEnum]: boolean;
  }>({
    personalInfoForm: true,
    attendeeInfo: false,
    paymentForm: false,
    surveyStep: false,
  });

  const { mutate: updateFanAttendeesInfo } =
    useUpdateFanAttendeesInfo(waitingListId);

  const filteredSteps = steps.filter((el) => stepsVisibility[el.key]);

  const {
    nextStep,
    prevStep,
    currentStep,
    isLastStep,
    isLoading,
    waitingList,
  } = useFormSteps(filteredSteps);

  const handleAttendeeForm = (attendeeValues: AttendeesInfoFormValidator[]) => {
    updateFanAttendeesInfo(
      { body: { attendees: attendeeValues } },
      {
        onSuccess: () => {
          nextStep();
        },
        onError: (err) => {
          notification.error({
            message: t('notification_error_attendees_info_send'),
            description:
              t(
                `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorDescription}`
              ) ?? err.message,
          });
        },
      }
    );
  };

  const contentStyle: React.CSSProperties = {
    marginTop: 32,
  };

  const StepComponent = (props: StepContentProps) => {
    return filteredSteps[currentStep].content(props);
  };

  useEffect(() => {
    if (waitingList && surveyInstances) {
      setStepsVisibility({
        ...stepsVisibility,
        [StepItemIdEnum.SURVEY_FORM]: !!surveyInstances.content.length,
      });
    }
  }, [surveyInstances]);

  useEffect(() => {
    if (waitingList) {
      setStepsVisibility({
        ...stepsVisibility,
        [StepItemIdEnum.ATTENDEE_INFO]:
          !!waitingList.eventRequiredAttendeeInfo?.length,
        [StepItemIdEnum.PAYMENT_FORM]: !waitingList.freeWaitingList,
      });
    }
  }, [waitingList]);

  return (
    <Modal
      open={open}
      style={{ maxWidth: 1284 }}
      title={t('checkout_title')}
      closeIcon={null}
      footer={false}
      width={'100%'}
      maskClosable
      onCancel={props.onCancel}
    >
      <Spin spinning={isLoading}>
        {filteredSteps.length > 1 ? (
          <Steps
            current={currentStep}
            items={filteredSteps.sort((a, b) => a.orderIndex - b.orderIndex)}
          />
        ) : null}
        <div style={contentStyle}>
          <StepComponent
            next={nextStep}
            prev={prevStep}
            isLastStep={isLastStep}
            waitingList={waitingList}
            handleAttendeeForm={handleAttendeeForm}
          />
        </div>
      </Spin>
    </Modal>
  );
}
