import React from 'react';
import {
  CreateSurveyQuestionDataValidatorSchema,
  CreateSurveyQuestionFormValidator,
  Language,
  QuestionItem,
  QuestionType,
  SurveyQuestionChoicesValidatorSchema,
  SurveyStructureQuestionValidatorSchema,
} from '@seaters-app/constants';
import {
  useCreateAdminQuestion,
  useCreateAdminQuestionChoices,
  useUpdateAdminQuestion,
  useUpdateAdminQuestionChoices,
} from '@seaters-app/data-access';
import { notification } from 'antd';
import { prepareValues } from '../helpers/prepareQuestionValues';
import { convertTranslation } from '../../../../../../utils/helpers/convertTranslations';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function useSubmitForm(
  question: QuestionItem | undefined,
  creation: boolean,
  setCurrentLanguage: (v: Language) => void,
  setQuestionModalOpen?: (v: boolean) => void,
  addToSurvey?: (v: QuestionItem) => void,
  setCreateFromScratch?: (v: boolean) => void,
  newQuestionProperties?: { enabled: boolean; mandatory: boolean }
) {
  const { questionnaireId = '' } = useParams();
  const { mutate: createQuestion, isLoading: isQuestionCreating } =
    useCreateAdminQuestion();

  const { t } = useTranslation();
  const { mutate: updateQuestion, isLoading: isQuestionUpdating } =
    useUpdateAdminQuestion(
      question ? question?.question.id : '',
      questionnaireId
    );

  const { mutate: createQuestionChoices, isLoading: isChoicesCreating } =
    useCreateAdminQuestionChoices();

  const { mutate: updateQuestionChoices, isLoading: isChoicesUpdating } =
    useUpdateAdminQuestionChoices(question ? question?.question.id : '');

  const isLoading =
    isQuestionCreating ||
    isChoicesUpdating ||
    isChoicesCreating ||
    isQuestionUpdating;

  const onSubmit = (values: CreateSurveyQuestionFormValidator) => {
    const {
      question: updatedQuestion,
      choices,
      structureFields,
      rangeQuestionChoices: rangeChoices,
    } = prepareValues(values);

    const parsedQuestionData = question?.question.id
      ? CreateSurveyQuestionDataValidatorSchema.safeParse({
          ...question?.question,
          ...updatedQuestion,
        })
      : CreateSurveyQuestionDataValidatorSchema.safeParse({
          ...question?.question,
          ...updatedQuestion,
        });

    const parsedChoicesData =
      SurveyQuestionChoicesValidatorSchema.safeParse(choices);

    const parsedRangeChoicesData =
      SurveyQuestionChoicesValidatorSchema.safeParse(rangeChoices);

    const parsedStructureFieldsData =
      SurveyStructureQuestionValidatorSchema.safeParse(structureFields);

    if (!parsedQuestionData.success) {
      console.log(parsedQuestionData.error);
      setCurrentLanguage(Language.EN);
      return;
    }
    if (!parsedChoicesData.success) {
      console.log(parsedChoicesData.error);
      setCurrentLanguage(Language.EN);
      return;
    }
    if (!parsedRangeChoicesData.success) {
      console.log(parsedRangeChoicesData.error);
      setCurrentLanguage(Language.EN);
      return;
    }
    if (!parsedStructureFieldsData.success) {
      console.log(parsedStructureFieldsData.error);
      return;
    }

    const questionDataToSend = {
      ...parsedQuestionData.data,
      text: convertTranslation(parsedQuestionData.data?.text),
      answerLabel: convertTranslation(parsedQuestionData.data?.answerLabel),
    };

    if (questionDataToSend?.type === QuestionType.STRUCTURED) {
      questionDataToSend.structure = parsedStructureFieldsData.data;
    }

    const questionChoices = parsedChoicesData.data.map((choice) => {
      return {
        order: choice.order,
        offeredAnswerTranslation: convertTranslation(
          choice.offeredAnswerTranslation
        ),
      };
    });

    const rangeQuestionChoices = parsedRangeChoicesData.data.map((choice) => {
      return {
        order: choice.order,
        offeredAnswerTranslation: convertTranslation(
          choice.offeredAnswerTranslation
        ),
      };
    });

    if (question) {
      updateQuestion(questionDataToSend, {
        onSuccess: async () => {
          if (
            questionDataToSend.type !== QuestionType.FREE_TEXT &&
            questionDataToSend.type !== QuestionType.STRUCTURED
          ) {
            updateQuestionChoices(
              rangeQuestionChoices.length
                ? rangeQuestionChoices
                : questionChoices,
              {
                onSuccess: () => {
                  notification.success({
                    message: t('notification_success_question_choices_updated'),
                  });
                },
                onError: (err) => {
                  notification.error({
                    message: t('notification_error_question_choices_updated'),
                    description:
                      t(
                        `${err?.response?.data?.errors?.errorsTypes?.validation_errors[0].error?.errorCode}`
                      ) ?? err.message,
                  });
                },
              }
            );
            notification.success({
              message: t('notification_success_question_updated'),
            });
          }

          if (setQuestionModalOpen) {
            setQuestionModalOpen(false);
          }
        },
        onError: (err) => {
          console.error(err);
          notification.error({
            message: t('notification_error_question_updated'),
          });
        },
      });
    } else {
      if (
        creation &&
        addToSurvey &&
        setCreateFromScratch &&
        newQuestionProperties
      ) {
        createQuestion(questionDataToSend, {
          onSuccess: (res) => {
            if (questionChoices.length) {
              createQuestionChoices({
                questionId: res.id,
                body: questionChoices,
              });
            }
            if (!questionChoices.length && rangeQuestionChoices.length) {
              createQuestionChoices({
                questionId: res.id,
                body: rangeQuestionChoices,
              });
            }
            notification.success({
              message: 'Question was created',
            });
            addToSurvey({
              question: res,
              mandatory: newQuestionProperties?.mandatory,
              enabled: newQuestionProperties?.enabled,
            });
            if (setQuestionModalOpen) {
              setQuestionModalOpen(false);
            }
          },
          onError: (err) => {
            console.error(err);
            notification.error({
              message: 'Question was not created',
            });
          },
        });
      }
    }
  };

  return { isLoading, onSubmit };
}

export default useSubmitForm;
