import {
  DisplayMode,
  Job,
  Option,
  SESSION_STORAGE_JOB_ID,
  WaitingListAcceptFormat,
} from '@seaters-app/constants';
import {
  useCreateBulkAccessCodes,
  useCreateBulkBadges,
  useCreateBulkWishLists,
  useDistributeBulkPositions,
  useUpdateBulkAccessCodes,
  useJob,
  useRemoveBulkBadges,
  useUpdateJob,
  useAssignUserRoles,
  downloadFileWithCustomName,
  useUpdateJobXLSX,
  useGeneratePDFTickets,
  useRemoveAccessCodes,
} from '@seaters-app/data-access';
import {
  Flex,
  Form,
  FormProps,
  Select,
  Space,
  Tabs,
  Typography,
  message,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, errorColor } from '@seaters-app/ui';
import BulkWishListsForm from './forms/BulkWishListsForm';
import XlsxUpload from './XlsxUpload';
import useUploadXlsx from './hooks/useUploadXlsx';
import Alerts from './components/Alerts';
import BulkBadgesForm from './forms/BulkBadgesForm';
import BulkPositionsDistributionForm from './forms/BulkPositionsDistributionForm';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import JobSteps from './JobSteps';
import BaseSupportActionForm from './forms/BaseSupportActionForm';

const { Title } = Typography;

const mapJobNameToAction: { [key: string]: string } = {
  bulkWishlistJob: 'bulk_wishlist_upload',
  accessCodeJob: 'bulk_access_codes_upload',
  bulkAccessCodesRemovingJob: 'bulk_access_codes_remove',
  badgesJob: 'bulk_badges_upload',
  deleteBadgesJob: 'bulk_badges_remove',
  bulkPositionDistributionJob: 'bulk_positions_distribution',
  bulkEnhancedAccessCodesUpdatingJob: 'bulk_update_access_codes',
  bulkRolesAssignmentJob: 'bulk_roles_assignment',
  pdfTicketPoolJob: 'pdf_tickets_pool',
};

const baseFormActionTypes = [
  'bulk_update_access_codes',
  'bulk_roles_assignment',
  'bulk_access_codes_remove',
];

const actions = [
  {
    label: 'admin_support_bulk_requests_wishlist_upload',
    value: 'bulk_wishlist_upload',
  },
  {
    label: 'admin_support_bulk_requests_select_action_export',
    value: 'export',
  },
  {
    label: 'admin_support_bulk_requests_access_codes_upload',
    value: 'bulk_access_codes_upload',
  },
  {
    label: 'admin_support_bulk_requests_badges_upload',
    value: 'bulk_badges_upload',
  },
  {
    label: 'admin_support_bulk_requests_badges_remove',
    value: 'bulk_badges_remove',
  },
  {
    label: 'admin_support_bulk_requests_positions_distribution',
    value: 'bulk_positions_distribution',
  },
  {
    label: 'admin_support_bulk_requests_update_access_codes',
    value: 'bulk_update_access_codes',
  },
  {
    label: 'admin_support_bulk_requests_roles_assignment',
    value: 'bulk_roles_assignment',
  },
  {
    label: 'admin_support_bulk_access_codes_remove',
    value: 'bulk_access_codes_remove',
  },
];

const ticketsActions = [
  {
    label: 'admin_support_pdf_tickets_pool',
    value: 'pdf_tickets_pool',
  },
];

const formItemLayout = {};

type FieldType = {
  action: string;
  directSales: boolean;
  directSalesWithoutSeat: boolean;
  displayMode: DisplayMode;
  file: File;
  fanGroupSlug: string;
  fanGroupId: string;
  waitinglistId: string;
  distributionType: 'WITH_SEATS' | 'WITHOUT_SEATS';
};

function ActionsPanel({ actions }: { actions: Option[] }) {
  const { t } = useTranslation();

  const [form] = Form.useForm<FieldType>();

  const actionValue = Form.useWatch('action', form);

  const [messageApi, contextHolder] = message.useMessage();

  const { handleUploadFile, xlsxKey, inputTicketsKey } =
    useUploadXlsx(messageApi);

  const [jobId, setJobId] = useState<string | null>(
    sessionStorage.getItem(SESSION_STORAGE_JOB_ID) ?? null
  );

  const [isJobCancelled, setJobCancelled] = useState(false);

  const {
    data: job,
    refetch,
    isLoading: isJobLoading,
    isRefetching: isJobRefetching,
    isFetching: isJobFetching,
  } = useJob(!isJobCancelled && jobId ? jobId : '');

  const [xlsxUrl, setXlsxUrl] = useState<string | null>(null);

  const [urlToShow, setUrlToShow] = useState(
    xlsxUrl || job?.latestXlsxSingedUrl || null
  );
  const [xlsxVersionKeyLatest, setXlsxVersionKeyLatest] = useState('');

  const actionsOptions = actions.map((action) => ({
    label: t(action.label),
    value: action.value,
  }));

  const { mutate: mutateJob, isLoading: isMutationLoading } = useUpdateJob();
  const { mutate: updateJobXLSX, isLoading: isXlsxUpdating } =
    useUpdateJobXLSX();

  const { mutate: generatePDFTickets, isLoading: isPDFTicketsGenerating } =
    useGeneratePDFTickets();

  const { mutate: createBulkWishLists, isLoading: isBulkWishListsLoading } =
    useCreateBulkWishLists();

  const { mutate: createBulkAccessCodes, isLoading: isBulkAccessCodesLoading } =
    useCreateBulkAccessCodes();

  const { mutate: createBulkBadges, isLoading: isBulkBadgesLoading } =
    useCreateBulkBadges();

  const { mutate: removeBulkBadges, isLoading: isRemoveBulkBadgesLoading } =
    useRemoveBulkBadges();

  const {
    mutate: distributeBulkPositions,
    isLoading: isDistributeBulkPositionsLoading,
  } = useDistributeBulkPositions();

  const {
    mutate: updateBulkAccessCodes,
    isLoading: isUpdateBulkAccessCodesLoading,
  } = useUpdateBulkAccessCodes();

  const { mutate: assignUserRoles, isLoading: isAssignUserRolesLoading } =
    useAssignUserRoles();

  const { mutate: removeAccessCodes, isLoading: isRemovingAccessCodesLoading } =
    useRemoveAccessCodes();

  const getLatestFileName = () => {
    return `${actionValue} - ${job?.latestStep}`;
  };
  const handleDownload = () => {
    downloadFileWithCustomName(urlToShow ?? null, getLatestFileName());
  };

  const handleDownloadTicketPools = () => {
    downloadFileWithCustomName(
      job?.context?.latestZipSignedUrl ?? null,
      'Ticket pools'
    );
  };

  const isLoading =
    isMutationLoading ||
    isBulkWishListsLoading ||
    isBulkAccessCodesLoading ||
    isBulkBadgesLoading ||
    isRemoveBulkBadgesLoading ||
    isDistributeBulkPositionsLoading ||
    isUpdateBulkAccessCodesLoading ||
    isAssignUserRolesLoading ||
    isRemovingAccessCodesLoading ||
    isPDFTicketsGenerating ||
    isJobFetching;

  const onSuccessAction = (res: Job) => {
    setJobId(res.id);
    sessionStorage.setItem(SESSION_STORAGE_JOB_ID, res.id);
    messageApi.destroy('startingJob');
    messageApi.open({
      key: 'startingJob',
      type: 'success',

      content: t('message_job_started_successfully'),
    });
  };

  const onFinish: FormProps<FieldType>['onFinish'] = (values) => {
    setJobCancelled(false);
    if (xlsxKey) {
      messageApi.open({
        key: 'startingJob',
        type: 'loading',
        content: t('message_starting_job'),
        duration: 0,
      });
      if (values.action === 'bulk_wishlist_upload') {
        createBulkWishLists(
          {
            xlsxKey,
            directSales: values.directSales,
            directSalesWithoutSeat: values.directSalesWithoutSeat,
            displayMode: values.displayMode,
            assetsDownloadFileFormat:
              WaitingListAcceptFormat.MULTIPAGE_PDF_FILE,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_access_codes_upload') {
        createBulkAccessCodes(
          {
            xlsxKey,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_badges_upload') {
        createBulkBadges(
          {
            xlsxKey,
            fanGroupSlug: values.fanGroupSlug,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_badges_remove') {
        removeBulkBadges(
          {
            xlsxKey,
            fanGroupSlug: values.fanGroupSlug,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_positions_distribution') {
        distributeBulkPositions(
          {
            xlsxKey,
            wishlistId: values.waitinglistId,
            distributionType: values.distributionType,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_update_access_codes') {
        updateBulkAccessCodes(
          {
            xlsxKey,
            fanGroupId: values.fanGroupId,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_roles_assignment') {
        assignUserRoles(
          {
            xlsxKey,
            fanGroupId: values.fanGroupId,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'bulk_access_codes_remove') {
        removeAccessCodes(
          {
            xlsxKey,
            fanGroupId: values.fanGroupId,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }

      if (values.action === 'pdf_tickets_pool' && inputTicketsKey) {
        generatePDFTickets(
          {
            xlsxKey,
            inputTicketsKey,
          },
          {
            onSuccess: (res) => {
              onSuccessAction(res);
            },
          }
        );
      }
    }
  };

  const cancelJob = () => {
    setJobId(null);
    setXlsxUrl(null);
    setUrlToShow('');
    setJobCancelled(true);
    sessionStorage.removeItem(SESSION_STORAGE_JOB_ID);
    refetch();
  };

  const handleJobAction = (action: 'moveNext' | 'cancel' | 'retryCurrent') => {
    if (job?.id)
      mutateJob(
        { jobId: job.id, action },
        {
          onSuccess: (data) => {},
          onError: ({ response }) => {
            if (response?.data.message === 'InvalidStatus') {
              notification.error({
                message: response.data.message,
                description: `${response.data.error} Status: ${job.status}`,
              });
            } else {
              notification.error({
                message: response?.data.message,
                description: response?.data.error,
              });
            }
          },
        }
      );
  };

  useEffect(() => {
    if (job?.xlsxVersionKeys[0] !== xlsxVersionKeyLatest) {
      setXlsxVersionKeyLatest(job?.xlsxVersionKeys[0] ?? '');
      setUrlToShow(job?.latestXlsxSingedUrl ?? '');
    }
  }, [job?.xlsxVersionKeys[0]]);

  useEffect(() => {
    if (
      job?.latestXlsxSingedUrl &&
      (!xlsxUrl || xlsxUrl !== job?.latestXlsxSingedUrl) &&
      !['running', 'pending'].includes(job.status)
    ) {
      setXlsxUrl(job?.latestXlsxSingedUrl);
    }
  }, [job?.latestXlsxSingedUrl]);

  useEffect(() => {
    form.setFieldsValue({
      action: job?.type ? mapJobNameToAction[job.type] : '',
    });
  }, [job?.type]);

  return (
    <Flex vertical style={{ width: '100%' }} gap={12}>
      {contextHolder}

      <Form
        initialValues={{
          action: job?.type ? mapJobNameToAction[job.type] : '',
          displayMode: DisplayMode.CLASSIC,
          directSales: false,
          directSalesWithoutSeat: false,
          fanGroupId: job?.context?.fanGroupId ? job.context.fanGroupId : '',
        }}
        layout="vertical"
        name="bulk_requests"
        {...formItemLayout}
        form={form}
        style={{
          maxWidth: 600,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t('admin_support_bulk_requests_select_action')}
          name="action"
          rules={[
            {
              required: true,
              message: t('admin_support_bulk_requests_select_action'),
            },
          ]}
        >
          <Select
            placeholder={t('admin_support_bulk_requests_select_action')}
            allowClear
            options={actionsOptions}
          />
        </Form.Item>
        {actionValue === 'bulk_wishlist_upload' ? <BulkWishListsForm /> : null}
        {actionValue === 'bulk_badges_upload' ||
        actionValue === 'bulk_badges_remove' ? (
          <BulkBadgesForm />
        ) : null}
        {actionValue === 'bulk_positions_distribution' ? (
          <BulkPositionsDistributionForm />
        ) : null}

        {baseFormActionTypes.includes(actionValue) ? (
          <BaseSupportActionForm />
        ) : null}

        <Form.Item label={t('upload_xlsx')}>
          <XlsxUpload messageApi={messageApi} onUpload={handleUploadFile} />
        </Form.Item>
        {actionValue === 'pdf_tickets_pool' && (
          <Form.Item label={t('upload_pdf_tickets')}>
            <XlsxUpload
              messageApi={messageApi}
              onUpload={(file) => handleUploadFile(file, 'pdf')}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Space>
            <Button
              disabled={!xlsxKey || (!!xlsxKey && !!job?.latestXlsxSingedUrl)}
              loading={
                isLoading &&
                !(!xlsxKey || (!!xlsxKey && !!job?.latestXlsxSingedUrl))
              }
              htmlType="submit"
              type="primary"
            >
              {t('start_action_btn')}
            </Button>
            {job && (
              <>
                <Button
                  type="primary"
                  color={errorColor}
                  loading={isJobLoading}
                  onClick={cancelJob}
                >
                  {t('cancel_job_btn')}
                </Button>
                <Button onClick={() => refetch()}>
                  {t('refetch_job_details_btn')}
                </Button>
                <Button
                  loading={isXlsxUpdating}
                  onClick={() =>
                    updateJobXLSX({ jobId: job?.id, key: xlsxKey ?? '' })
                  }
                >
                  {t('update_job_xlsx_button_text')}
                </Button>
              </>
            )}
          </Space>
        </Form.Item>
      </Form>
      {!!job && <JobSteps job={job} />}

      {job && (
        <Space>
          <Button
            danger
            onClick={() => handleJobAction('cancel')}
            disabled={
              !['awaitingNextStep', 'cancelled', 'failed'].includes(job.status)
            }
          >
            {t('cancel_step_btn')}
          </Button>

          <Button
            icon={<ReloadOutlined rev={undefined} />}
            onClick={() => handleJobAction('retryCurrent')}
            disabled={
              !['awaitingNextStep', 'cancelled', 'failed'].includes(job.status)
            }
          >
            {t('retry_current_step_btn')}
          </Button>
          <Button
            type="primary"
            onClick={() => handleJobAction('moveNext')}
            disabled={!(job.nextStep && job.status === 'awaitingNextStep')}
          >
            {t('start_next_step_btn')}
          </Button>
        </Space>
      )}
      <Alerts job={job} />
      <Space>
        {urlToShow && (
          <Button
            icon={<DownloadOutlined rev={undefined} />}
            onClick={handleDownload}
          >
            {t('download_latest_file_button')}
          </Button>
        )}
        {job?.context?.latestZipSignedUrl &&
          actionValue === 'pdf_tickets_pool' && (
            <Button
              icon={<DownloadOutlined rev={undefined} />}
              onClick={handleDownloadTicketPools}
            >
              {t('download_ticket_pools_button')}
            </Button>
          )}
      </Space>
      {urlToShow && (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            urlToShow
          )}`}
          style={{
            width: '100%',
            height: '50vh',
          }}
        ></iframe>
      )}
    </Flex>
  );
}

function SupportActions() {
  const { t } = useTranslation();

  return (
    <Flex
      vertical
      style={{
        padding: 24,
        maxWidth: '100%',
        width: '100%',
      }}
    >
      <Title level={2}>{t('admin_menu_support')}</Title>
      <Tabs defaultActiveKey="bulk_requests">
        <Tabs.TabPane
          tab={t('admin_support_bulk_requests')}
          key="bulk_requests"
        >
          <ActionsPanel actions={actions} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('admin_support_ticket_management')}
          key="tickets_management"
        >
          <ActionsPanel actions={ticketsActions} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={t('admin_support_reporting')}
          key="reporting"
          disabled
        ></Tabs.TabPane>
      </Tabs>
    </Flex>
  );
}

export default SupportActions;
